<template>
  <ApplicationNannyInputRecommendationsRead
    v-if="state.mode === VIEW_MODES.READ"
    :recommendations="recommendations"
    @create-mode="showCreate"
    @update-mode="showUpdate"
    @delete="handleDelete"
  />

  <ApplicationNannyInputRecommendationsCreate
    v-else-if="state.mode === VIEW_MODES.CREATE"
    @create="handleCreate"
    @cancel="handleCancel"
  />

  <ApplicationNannyInputRecommendationsUpdate
    v-else-if="state.mode === VIEW_MODES.UPDATE"
    :recommendation="state.recommendationToUpdate"
    @update="handleUpdate"
    @cancel="handleCancel"
  />
</template>

<script>
import { reactive } from "@vue/reactivity";

import ApplicationNannyInputRecommendationsCreate from "@/components/Application/Nanny/ApplicationNannyInputRecommendationsCreate.vue";
import ApplicationNannyInputRecommendationsRead from "@/components/Application/Nanny/ApplicationNannyInputRecommendationsRead.vue";
import ApplicationNannyInputRecommendationsUpdate from "@/components/Application/Nanny/ApplicationNannyInputRecommendationsUpdate.vue";
import { watch } from "vue";

export const VIEW_MODES = {
  CREATE: "CREATE",
  READ: "READ",
  UPDATE: "UPDATE",
};

export default {
  components: {
    ApplicationNannyInputRecommendationsCreate,
    ApplicationNannyInputRecommendationsRead,
    ApplicationNannyInputRecommendationsUpdate,
  },

  props: {
    recommendations: Array,
  },

  emits: ["create", "update", "delete", "update:mode"],

  setup(_, { emit }) {
    const state = reactive({
      mode: VIEW_MODES.READ,
      recommendationToUpdate: null,
    });

    watch(
      () => state.mode,
      (newMode) => {
        emit("update:mode", newMode);
      },
      { immediate: true }
    );

    const showCreate = () => {
      state.mode = VIEW_MODES.CREATE;
    };

    const showRead = () => {
      state.mode = VIEW_MODES.READ;
    };

    const showUpdate = (recommendation) => {
      state.recommendationToUpdate = recommendation;
      state.mode = VIEW_MODES.UPDATE;
    };

    const handleCreate = (recommendation) => {
      emit("create", recommendation);
      showRead();
    };

    const handleUpdate = (id, payload) => {
      emit("update", id, payload);
      showRead();
    };

    const handleCancel = () => {
      showRead();
    };

    const handleDelete = (id) => {
      emit("delete", id);
    };

    return {
      VIEW_MODES,
      showCreate,
      showUpdate,

      handleCreate,
      handleUpdate,
      handleCancel,
      handleDelete,

      state,
    };
  },
};
</script>
