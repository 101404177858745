import gql from "graphql-tag";

export const GET_APPLICATION_NANNY_RECOMMENDATION = gql`
  query ApplicationNannyRecommendation($id: ID!) {
    applicationNannyRecommendation(_id: $id) {
      _id
      application_nanny_id
      source
      text
      rating
      proofName
      proofContact
      isVerified
    }
  }
`;

export const GET_APPLICATION_NANNY_RECOMMENDATIONS = gql`
  query ApplicationNannyRecommendations(
    $ids: [ID!]
    $filters: ListApplicationNannyRecommendationInput
    $populatePath: String
    $populate: PopulateInput
  ) {
    applicationNannyRecommendations(
      ids: $ids
      filters: $filters
      populatePath: $populatePath
      populate: $populate
    ) {
      _id
      application_nanny_id
      source
      text
      rating
      proofName
      proofContact
      isVerified
    }
  }
`;
