import {
  CREATE_APPLICATION_NANNY_RECOMMENDATION,
  DELETE_APPLICATION_NANNY_RECOMMENDATION,
  UPDATE_APPLICATION_NANNY_RECOMMENDATION,
} from "@/graphql/mutations/applicationNannyRecommendation";
import {
  GET_APPLICATION_NANNY_RECOMMENDATION,
  GET_APPLICATION_NANNY_RECOMMENDATIONS,
} from "@/graphql/queries/applicationNannyRecommendation";
import { apolloClient } from "@/plugins/apollo";

const applicationNannyRecommendationsApi = {
  async getOne(id) {
    if (!id) {
      throw new Error("No id provided!");
    }

    const { data } = await apolloClient.query({
      query: GET_APPLICATION_NANNY_RECOMMENDATION,
      variables: {
        id,
      },
      fetchPolicy: "network-only",
    });

    return data.applicationNannyRecommendation;
  },

  async getMany({ ids, populatePath, populate, filters }) {
    const { data } = await apolloClient.query({
      query: GET_APPLICATION_NANNY_RECOMMENDATIONS,
      variables: {
        ids,
        populatePath,
        populate,
        filters,
      },
      fetchPolicy: "network-only",
    });

    return JSON.parse(JSON.stringify(data.applicationNannyRecommendations));
  },

  async createOne(payload) {
    if (!payload) {
      throw new Error("No payload provided!");
    }

    const { data } = await apolloClient.mutate({
      mutation: CREATE_APPLICATION_NANNY_RECOMMENDATION,
      variables: {
        payload,
      },
    });

    return data.createApplicationNannyRecommendation;
  },

  async updateOne(id, payload) {
    if (!id) {
      throw new Error("No id provided!");
    }
    if (!payload) {
      throw new Error("No payload provided!");
    }

    const { data } = await apolloClient.mutate({
      mutation: UPDATE_APPLICATION_NANNY_RECOMMENDATION,
      variables: {
        id,
        payload,
      },
    });

    return data.updateApplicationNannyRecommendation;
  },

  async deleteOne(id) {
    if (!id) {
      throw new Error("No id provided!");
    }

    const { data } = await apolloClient.mutate({
      mutation: DELETE_APPLICATION_NANNY_RECOMMENDATION,
      variables: {
        id,
      },
    });

    return data.deleteApplicationNannyRecommendation;
  },
};

export default applicationNannyRecommendationsApi;
