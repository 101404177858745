import applicationNannyRecommendationsApi from "@/api/applicationNannyRecommendations";
import { onMounted, ref } from "vue";

export default function useApplicationNannyRecommendations(applicationId) {
  const recommendations = ref([]);
  const isLoading = ref(false);
  const mode = ref(null);

  onMounted(() => {
    console.log("2");
    loadRecommendations();
  });

  const loadRecommendations = async () => {
    console.log("3");
    try {
      isLoading.value = true;
      const filters = {
        application_nanny_id: applicationId,
      };
      recommendations.value = await applicationNannyRecommendationsApi.getMany({
        filters,
      });
    } catch (error) {
      alert(error.message);
    } finally {
      isLoading.value = false;
    }
  };

  const handleCreate = async (recommendation) => {
    try {
      isLoading.value = true;
      const payload = {
        ...recommendation,
        application_nanny_id: applicationId,
        rating: 2, // 1 -> not recommend ; 2 -> recommend
        isVerified: false,
      };
      await applicationNannyRecommendationsApi.createOne(payload);
    } catch (error) {
      alert(error.message);
    } finally {
      loadRecommendations();
    }
  };

  const handleUpdate = async (id, payload) => {
    try {
      isLoading.value = true;
      await applicationNannyRecommendationsApi.updateOne(id, payload);
    } catch (error) {
      alert(error.message);
    } finally {
      loadRecommendations();
    }
  };

  const handleDelete = async (id) => {
    try {
      isLoading.value = true;
      await applicationNannyRecommendationsApi.deleteOne(id);
    } catch (error) {
      alert(error.message);
    } finally {
      loadRecommendations();
    }
  };

  return {
    recommendations,
    isLoading,
    mode,
    handleCreate,
    handleUpdate,
    handleDelete,
  };
}
