<template>
  <div class="container">
    <div class="wrapper-h1">
      <BaseH1 :text="'Congratulacje!'" class="h1 wrapper-h1__h1" />
      <TextDescription
        :text="'Jesteś gwiazdą! Twój profil jest wypełniony o 97%. Dodaj referencje, by błyszczeć jeszcze jaśniej :)'"
      />
    </div>

    <ButtonCta @click="showCreate" :label="'Dodaj referencje'" />
  </div>
</template>

<script>
import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import TextDescription from "@/components/UI/TextDescription.vue";
import ButtonCta from "@/components/UI/Base/ButtonCta.vue";

export default {
  components: {
    BaseH1,
    TextDescription,
    ButtonCta,
  },

  emits: ["create-mode"],

  setup(_, { emit }) {
    const showCreate = () => {
      emit("create-mode");
    };

    return {
      showCreate,
    };
  },
};
</script>

<style scoped>
.container {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 100px;
}
.wrapper-h1 {
  width: 360px;
  margin-top: -20px;

  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 20px;
}

@media (max-width: 1200px) {
  .container {
    width: 100%;

    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 100px;
  }
  .wrapper-h1 {
    width: 100%;
    margin-top: 0;

    display: flex;
    flex-flow: column;
    align-items: center;
    row-gap: 20px;
  }
}
</style>
