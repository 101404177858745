<template>
  <div class="container">
    <div class="wrapper-h1">
      <BaseH1 :text="'Update recommendation!'" class="wrapper-h1__h1" />
    </div>

    <ApplicationNannyInputRecommendationsForm
      v-model="recommendationPayload"
      @update:is-valid="setIsValid"
    />

    <div class="wrapper-buttons">
      <ButtonTertiary @click="handleCancel" :label="'Anuluj'" />
      <ButtonPrimarySave @click="handleSave" :is-disabled="isValid === false" />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import ButtonPrimarySave from "@/components/UI/ButtonPrimarySave.vue";
import ButtonTertiary from "@/components/UI/Base/ButtonTertiary.vue";
import ApplicationNannyInputRecommendationsForm from "@/components/Application/Nanny/ApplicationNannyInputRecommendationsForm.vue";

export default {
  components: {
    BaseH1,
    ButtonPrimarySave,
    ButtonTertiary,
    ApplicationNannyInputRecommendationsForm,
  },

  props: {
    recommendation: Object,
  },

  emits: ["update", "cancel"],

  setup(props, { emit }) {
    const recommendationPayload = ref({
      source: props.recommendation.source,
      text: props.recommendation.text,
      proofName: props.recommendation.proofName,
      proofContact: props.recommendation.proofContact,
    });

    const isValid = ref(false);

    const setIsValid = (newIsValid) => {
      isValid.value = newIsValid;
    };

    const handleCancel = () => {
      emit("cancel");
    };

    const handleSave = () => {
      if (isValid.value === false) {
        return;
      }
      emit("update", props.recommendation._id, recommendationPayload.value);
    };

    return {
      recommendationPayload,
      isValid,

      setIsValid,
      handleCancel,
      handleSave,
    };
  },
};
</script>

<style scoped>
.container {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 60px;
}
.wrapper-h1 {
  width: 360px;
  margin-top: -10px;

  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 20px;
}
.wrapper-buttons {
  width: 360px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1200px) {
  .container {
    width: 100%;

    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 40px;
  }
  .wrapper-h1 {
    width: 100%;
    margin-top: 0;

    display: flex;
    flex-flow: column;
    align-items: center;
    row-gap: 20px;
  }
  .wrapper-buttons {
    width: 100%;
  }
}
</style>
