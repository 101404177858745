<template>
  <div @click="handleUpdate" class="recommendation">
    <BaseButtonIconCross
      @click.stop="handleDelete"
      class="recommendation__delete-link"
    />

    <BaseH3
      :text="`Referencja # ${recommendation._id}`"
      class="recommendation__title"
    />

    <BaseText :text="textReady" />
  </div>
</template>

<script>
import BaseButtonIconCross from "@/components/UI/Base/BaseButtonIconCross.vue";
import BaseText from "@/components/UI/Base/BaseText.vue";
import BaseH3 from "@/components/UI/Base/BaseH3.vue";
import { computed } from "vue";

export default {
  components: {
    BaseButtonIconCross,
    BaseText,
    BaseH3,
  },

  props: {
    recommendation: Object,
  },

  emits: ["update", "delete"],

  setup(props, { emit }) {
    const handleUpdate = () => {
      emit("update", props.recommendation);
    };

    const handleDelete = () => {
      emit("delete", props.recommendation._id);
    };

    const textReady = computed(() => {
      if (props.recommendation.text.length > 200) {
        return props.recommendation.text.slice(0, 200) + "…";
      }
      return props.recommendation.text;
    });

    return {
      handleDelete,
      handleUpdate,
      textReady,
    };
  },
};
</script>

<style scoped>
.recommendation {
  position: relative;
  width: 100%;
  padding: 20px 60px;
  display: flex;
  flex-flow: column;
  row-gap: 12px;

  background: rgba(58, 41, 41, 0.02);
  border-radius: 20px;
}
.recommendation:hover {
  cursor: pointer;
}
.recommendation__delete-link {
  position: absolute;
  top: 15px;
  right: 15px;
  opacity: 0.6;
}

@media (max-width: 1200px) {
  .recommendation {
    position: relative;
    width: 100vw;
    padding: 20px 60px;
    display: flex;
    flex-flow: column;

    background: rgba(58, 41, 41, 0.02);
    border-radius: 20px;
  }
}
</style>
