<template>
  <div class="recommendations-container">
    <BaseH1 :text="'Referencje'" />

    <div class="wrapper-recommendations">
      <ApplicationNannyInputRecommendationsReadListItem
        v-for="recommendation in recommendations"
        :key="recommendation.id"
        :recommendation="recommendation"
        :proof-contact="recommendation.proofContact"
        @update="handleUpdate"
        @delete="handleDelete"
      />

      <ButtonLink :label="'Dodaj kolejną referencję'" @click="handleCreate" />
    </div>
  </div>
</template>

<script>
import ButtonLink from "@/components/UI/Base/ButtonLink.vue";
import ApplicationNannyInputRecommendationsReadListItem from "@/components/Application/Nanny/ApplicationNannyInputRecommendationsReadListItem.vue";
import BaseH1 from "@/components/UI/Base/BaseH1.vue";

export default {
  components: {
    ButtonLink,
    ApplicationNannyInputRecommendationsReadListItem,
    BaseH1,
  },

  props: {
    recommendations: {
      type: Array,
      required: true,
    },
  },

  emits: ["create-mode", "update-mode", "delete"],

  setup(_, { emit }) {
    const handleCreate = () => {
      emit("create-mode");
    };

    const handleUpdate = (recommendation) => {
      emit("update-mode", recommendation);
    };

    const handleDelete = (id) => {
      emit("delete", id);
    };

    return {
      handleCreate,
      handleUpdate,
      handleDelete,
    };
  },
};
</script>

<style scoped>
.recommendations-container {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 60px;
}
.wrapper-recommendations {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 40px;
}

@media (max-width: 1200px) {
}
</style>
