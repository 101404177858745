<template>
  <ApplicationNannyInputRecommendationsReadEmpty
    v-if="recommendations.length === 0"
    @create-mode="showCreate"
  />

  <ApplicationNannyInputRecommendationsReadList
    v-else
    :recommendations="recommendations"
    @create-mode="showCreate"
    @update-mode="showUpdate"
    @delete="handleDelete"
  />
</template>

<script>
import ApplicationNannyInputRecommendationsReadEmpty from "@/components/Application/Nanny/ApplicationNannyInputRecommendationsReadEmpty.vue";
import ApplicationNannyInputRecommendationsReadList from "@/components/Application/Nanny/ApplicationNannyInputRecommendationsReadList.vue";

export default {
  components: {
    ApplicationNannyInputRecommendationsReadEmpty,
    ApplicationNannyInputRecommendationsReadList,
  },

  props: {
    recommendations: Array,
  },

  emits: ["create-mode", "update-mode", "delete"],

  setup(_, { emit }) {
    const showCreate = () => {
      emit("create-mode");
    };

    const showUpdate = (recommendation) => {
      emit("update-mode", recommendation);
    };

    const handleDelete = (id) => {
      emit("delete", id);
    };

    return {
      showCreate,
      showUpdate,
      handleDelete,
    };
  },
};
</script>

<style scoped>
.container {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 100px;
}
.wrapper-h1 {
  width: 360px;
  margin-top: -20px;

  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 20px;
}
.wrapper-buttons {
  display: flex;
  flex-flow: column;
  row-gap: 30px;
  align-items: center;
}

@media (max-width: 1200px) {
  .container {
    width: 100%;

    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 100px;
  }
  .wrapper-h1 {
    width: 100%;
    margin-top: 0;

    display: flex;
    flex-flow: column;
    align-items: center;
    row-gap: 20px;
  }
  .wrapper-buttons {
    display: flex;
    flex-flow: column;
    row-gap: 30px;
    align-items: center;
  }
}
</style>
