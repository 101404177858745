<template>
  <div class="container-recommendation">
    <div class="wrapper-inputs container-recommendation__wrapper-inputs">
      <InputTextTextarea2RowsWide
        v-model="recommendation.source"
        @update:is-valid-result="setIsValidSource"
        :name="'source'"
        :label="'Zrodło'"
        :asterisk="true"
        :placeholder="'Zrodło'"
      />
      <InputTextTextarea6RowsWide
        v-model="recommendation.text"
        @update:is-valid-result="setIsValidText"
        :name="'text'"
        :maxlength="400"
        :label="'Dodaj treść referencji'"
        :asterisk="true"
        :placeholder="'treść referencji'"
      />
      <InputText
        v-model="recommendation.proofName"
        :type="'text'"
        :name="'proof-name'"
        :autocomplete="'off'"
        :spellcheck="false"
        :label="'Imię i nazwisko osoby rekomendującej'"
        :placeholder="'Wierzchoślawa'"
        :style="{ width: '100%' }"
      />
      <InputText
        v-model="recommendation.proofContact"
        :type="'text'"
        :name="'proof-contact'"
        :autocomplete="'off'"
        :spellcheck="false"
        :label="'Nr telefonu/email  osoby rekomendującej'"
        :placeholder="'Wierzchoślawa'"
        :style="{ width: '100%' }"
      />
    </div>

    <TextHint
      >Podanie danych osoby rekomendującej jest wymagane tylko w celu
      sprawdzenia referencji przez nasz zespol oraz nadania jej statusu
      “zweryfikowanej”.
      <br />
      <br />
      Jeżeli nie wypełnisz te pola, referencja będzie widoczna w twoim profilu,
      ale pod statusem “nie zweryfikowana”.
    </TextHint>
  </div>
</template>

<script>
import { computed, reactive, toRefs, watch } from "vue";

import InputTextTextarea2RowsWide from "@/components/UI/InputTextTextarea2RowsWide.vue";
import InputTextTextarea6RowsWide from "@/components/UI/InputTextTextarea6RowsWide.vue";
import InputText from "@/components/UI/Base/InputText.vue";
import TextHint from "@/components/UI/TextHint.vue";

export default {
  components: {
    InputTextTextarea2RowsWide,
    InputTextTextarea6RowsWide,
    InputText,
    TextHint,
  },

  props: {
    modelValue: Object,
  },

  emits: ["update:modelValue", "update:isValid"],

  setup(props, { emit }) {
    const state = reactive({
      recommendation: {
        source: props.modelValue.source,
        text: props.modelValue.text,
        proofName: props.modelValue.proofName,
        proofContact: props.modelValue.proofContact,
      },
      isValidSource: false,
      isValidText: false,
    });

    const isValid = computed(() => {
      return state.isValidSource && state.isValidText;
    });

    // Update recommendation when recommendation prop is changed
    watch(
      () => props.modelValue,
      (newRecommendation) => {
        state.recommendation = newRecommendation;
      },
      { deep: true }
    );

    // Emit update:recommendation on changes
    watch(
      () => state.recommendation,
      (newRecommendation) => {
        emit("update:modelValue", newRecommendation);
      },
      { deep: true }
    );

    watch(
      isValid,
      (newIsValid) => {
        emit("update:isValid", newIsValid);
      },
      { immediate: true }
    );

    const setIsValidSource = (newIsValid) => {
      state.isValidSource = newIsValid;
    };

    const setIsValidText = (newIsValid) => {
      state.isValidText = newIsValid;
    };

    return {
      ...toRefs(state),

      setIsValidSource,
      setIsValidText,
    };
  },
};
</script>

<style scoped>
.container-recommendation {
  position: relative;
  width: 100%;
  padding: 60px 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 60px;

  background: rgba(58, 41, 41, 0.02);
  border-radius: 20px;
}
.container-recommendation__delete-link {
  position: absolute;
  top: 15px;
  right: 15px;
  opacity: 0.6;
}
.container-recommendation__title {
  margin-top: -10px;
}

.container-recommendation__wrapper-inputs {
  width: 560px;

  display: flex;
  flex-flow: column;
  align-items: center;

  row-gap: 25px;
}

@media (max-width: 1200px) {
  .container-recommendation {
    position: relative;
    width: 100vw;
    padding: 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    row-gap: 40px;

    background: unset;
    border-radius: 0px;
  }
  .container-recommendation__wrapper-inputs {
    width: var(--layout-default--width);

    display: flex;
    flex-flow: column;
    align-items: center;

    row-gap: 25px;
  }
}
</style>
