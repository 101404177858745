import gql from "graphql-tag";

export const CREATE_APPLICATION_NANNY_RECOMMENDATION = gql`
  mutation CreateApplicationNannyRecommendation(
    $payload: CreateApplicationNannyRecommendationInput!
  ) {
    createApplicationNannyRecommendation(payload: $payload) {
      _id
    }
  }
`;

export const UPDATE_APPLICATION_NANNY_RECOMMENDATION = gql`
  mutation UpdateApplicationNannyRecommendation(
    $id: ID!
    $payload: UpdateApplicationNannyRecommendationInput!
  ) {
    updateApplicationNannyRecommendation(_id: $id, payload: $payload) {
      _id
    }
  }
`;

export const DELETE_APPLICATION_NANNY_RECOMMENDATION = gql`
  mutation DeleteApplicationNannyRecommendation($id: ID!) {
    deleteApplicationNannyRecommendation(_id: $id) {
      _id
    }
  }
`;
